define("pilots/components/pilot-license-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MIN_LICENSE_NUM_LENGTH = 6;
  const MAX_LICENSE_NUM_LENGTH = 32;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    certificateType: null,
    certificateNumber: '',
    inputDate: '',
    expirationDate: '',
    issuingBody: '',
    certificatePreview: null,
    pilot: null,
    isModalOpen: false,
    isAddingLicense: false,
    certificateError: '',
    dateError: '',
    licenseNumberError: '',
    imageUploadError: '',
    currentLicense: Ember.computed(function () {
      return {
        tempSrc: null,
        image: null
      };
    }),
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      Ember.run.scheduleOnce('afterRender', this, this.initFileInputChangeHandler);
    },
    initFileInputChangeHandler() {
      const fileInput = this.element.querySelector('#uploadCertificate');
      if (fileInput) {
        fileInput.removeEventListener('change', this.handleFileInputChangeBound);
        this.handleFileInputChangeBound = this.handleFileInputChange.bind(this);
        fileInput.addEventListener('change', this.handleFileInputChangeBound);
      }
    },
    resetForm() {
      this.setProperties({
        certificateType: null,
        certificateNumber: '',
        inputDate: '',
        expirationDate: '',
        issuingBody: '',
        certificatePreview: '',
        certificateError: '',
        dateError: '',
        licenseNumberError: '',
        imageUploadError: ''
      });
      this.set('currentLicense.tempSrc', '');
      this.set('currentLicense.image', null);
      const fileInput = this.element.querySelector('#uploadCertificate');
      if (fileInput) {
        fileInput.value = null;
      }
      const uploadContainer = this.element.querySelector('#uploadContainer');
      if (uploadContainer) {
        uploadContainer.classList.remove('has-image');
      }
    },
    clearValidationErrors() {
      this.set('dateError', '');
      this.set('certificateError', '');
      this.set('imageUploadError', '');
      this.set('licenseNumberError', '');
      this.set('issuingBodyError', '');
    },
    certificateTypeObserver: Ember.observer('certificateType', function () {
      const certificateType = this.get('certificateType');
      if (certificateType) {
        this.clearValidationErrors();
      }
    }),
    licenseNumberObserver: Ember.observer('certificateNumber', 'certificateType', 'userHasInteracted', function () {
      const certificateType = this.get('certificateType');
      if (!certificateType) {
        return;
      }
      let license = this.store.peekRecord('license', certificateType);
      const certificateNumber = this.get('certificateNumber').trim();
      const alphaNumerics = /^[0-9a-zA-Z\-#]+$/;
      this.set('licenseNumberError', '');
      if (license && license.get('is_certificate_number_required')) {
        if (!certificateNumber && this.get('userHasInteracted')) {
          this.set('licenseNumberError', 'Please enter a license number.');
        } else if (certificateNumber.length < MIN_LICENSE_NUM_LENGTH || certificateNumber.length > MAX_LICENSE_NUM_LENGTH) {
          this.set('licenseNumberError', `License number can only contain ${MIN_LICENSE_NUM_LENGTH} and ${MAX_LICENSE_NUM_LENGTH} alphanumeric characters, "#" and "-".`);
        } else if (!alphaNumerics.test(certificateNumber)) {
          this.set('licenseNumberError', 'License number can only contain alphanumeric characters, "#" and "-".');
        }
      }
    }),
    inputDateObserver: Ember.observer('inputDate', 'certificateType', function () {
      const certificateType = this.get('certificateType');
      if (!certificateType) {
        this.set('dateError', 'No certificate type selected.');
        return;
      }
      const validDate = this.get('validDate');
      if (!validDate) {
        let license = this.store.peekRecord('license', certificateType);
        if (license && license.get('reference_date_mode') === 'expiration_date') {
          this.set('dateError', 'Invalid expiration date.');
        } else if (license && license.get('reference_date_mode') === 'issue_date') {
          this.set('dateError', 'Invalid issue date.');
        } else {
          this.set('dateError', 'Please choose a valid date.');
        }
      } else {
        this.set('dateError', '');
      }
    }),
    issuingBodyObserver: Ember.observer('issuingBody', 'certificateType', function () {
      const certificateType = this.get('certificateType');
      if (!certificateType) {
        this.set('issuingBodyError', 'No certificate type selected.');
        return;
      }
      const isVisible = this.get('issuingBodyVisibility').show;
      const label = this.get('issuingBodyVisibility').label.toLowerCase();
      if (isVisible && !this.get('issuingBody').trim()) {
        const errorMessage = `Please enter the issuing body (${label}).`;
        this.set('issuingBodyError', errorMessage);
      } else {
        this.set('issuingBodyError', '');
      }
    }),
    dateErrorClass: Ember.computed('dateError', function () {
      return this.get('dateError') ? 'form-group-calendar-error' : 'form-group-calendar';
    }),
    licenseNumberErrorClass: Ember.computed('licenseNumberError', function () {
      return this.get('licenseNumberError') ? 'form-group-error' : 'form-group';
    }),
    imageUploadErrorClass: Ember.computed('imageUploadError', function () {
      return this.get('imageUploadError') ? 'form-group-error' : 'form-group';
    }),
    selectStyle: Ember.computed('certificateType', function () {
      return this.get('certificateType') ? 'color: rgb(24, 26, 28);' : 'color: rgb(106, 115, 128);';
    }),
    dateInputStyle: Ember.computed('inputDate', function () {
      return this.get('inputDate') ? 'color: rgb(24, 26, 28);' : 'color: rgb(106, 115, 128);';
    }),
    showSection: Ember.computed.or('dateInputVisibility', 'issuingBodyVisibility.show'),
    dateInputLabel: Ember.computed('certificateType', function () {
      let certificateType = this.get('certificateType');
      if (!certificateType) {
        return null;
      }
      let license = this.store.peekRecord('license', certificateType);
      if (!license) {
        console.error('License not found:', certificateType);
        return null;
      }
      let mode = license.get('reference_date_mode');
      switch (mode) {
        case 'issue_date':
          return 'Issue Date';
        case 'expiration_date':
          return 'Expiration Date';
        default:
          return null;
      }
    }),
    dateInputVisibility: Ember.computed('dateInputLabel', function () {
      return Boolean(this.get('dateInputLabel'));
    }),
    issuingBodyVisibility: Ember.computed('certificateType', function () {
      const certificateType = this.get('certificateType');
      if (certificateType) {
        let license = this.get('store').peekRecord('license', certificateType);
        if (license && license.get('issuer_type') === 'many_issuing_body') {
          return {
            show: true,
            label: license.get('issuing_body')
          };
        }
      }
      return {
        show: false,
        label: ''
      };
    }),
    validLicenseNumber: Ember.computed('certificateNumber', 'certificateType', function () {
      const certificateNumber = this.get('certificateNumber');
      const certificateType = this.get('certificateType');
      let license = this.store.peekRecord('license', certificateType);
      if (!license || !license.get('is_certificate_number_required')) {
        return true;
      }
      if (certificateNumber === '' && license.get('is_certificate_number_required')) {
        return false;
      }
      const licenseNumberLength = certificateNumber.length;
      if (licenseNumberLength >= MIN_LICENSE_NUM_LENGTH && licenseNumberLength <= MAX_LICENSE_NUM_LENGTH) {
        const alphaNumerics = /^[0-9a-zA-Z\-#]+$/;
        return alphaNumerics.test(certificateNumber);
      }
      return false;
    }),
    referenceDate: Ember.computed('model.license.{reference_date_mode,validity_period}', 'inputDate', function () {
      let mode = this.get('model.license.reference_date_mode');
      let inputDate = this.get('inputDate');
      let monthsToAdd = this.get('model.license.validity_period');
      if (!inputDate || mode === 'not_applicable') {
        return null;
      }
      return mode === 'expiration_date' ? (0, _moment.default)(inputDate).add(monthsToAdd, 'months').format('YYYY-MM-DD') : inputDate;
    }),
    validDate: Ember.computed('inputDate', 'certificateType', function () {
      const inputDate = this.get('inputDate');
      const certificateType = this.get('certificateType');
      let license = this.store.peekRecord('license', certificateType);
      if (!inputDate || !license) {
        return false;
      }
      const date = (0, _moment.default)(inputDate, 'YYYY-MM-DD');
      if (!date.isValid()) {
        return false;
      }
      const mode = license.get('reference_date_mode');
      const today = (0, _moment.default)();
      switch (mode) {
        case 'issue_date':
          return date.isSameOrBefore(today);
        case 'expiration_date':
          return date.isSameOrAfter(today);
        default:
          return true;
      }
    }),
    validateImage(file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'image/heic', 'image/heif', 'image/tiff', 'image/bmp', 'image/webp'];
      const maxSize = 20 * 1024 * 1024; // 20 MB

      let errors = [];
      if (!allowedTypes.includes(file.type)) {
        errors.push('Invalid file type. Only JPEG, PDF, and PNG files are allowed.');
      }
      if (file.size > maxSize) {
        errors.push('File size exceeds the maximum limit of 20 MB.');
      }
      return errors.join(' ');
    },
    validateCertificateType() {
      const certificateType = this.get('certificateType');
      if (!certificateType || certificateType === 'Select Document Type' || certificateType === '') {
        this.set('certificateError', 'Please select a document type.');
        return false;
      }
      this.set('certificateError', '');
      return true;
    },
    validateLicenseNumber() {
      const certificateType = this.get('certificateType');
      const license = this.store.peekRecord('license', certificateType);
      if (!license) {
        console.error('No license found with id:', certificateType);
        this.set('licenseNumberError', 'License type not found.');
        return false;
      }
      if (license.get('is_certificate_number_required') && !this.get('validLicenseNumber')) {
        this.set('licenseNumberError', `Please enter a valid license number. (6-32 alphanumeric characters, including "#", "-")`);
        return false;
      }
      this.set('licenseNumberError', '');
      return true;
    },
    validateDate() {
      const license = this.store.peekRecord('license', this.get('certificateType'));
      if (!license || license.get('reference_date_mode') === 'not_applicable') {
        return true;
      }
      if (!this.get('inputDate').trim() || !this.get('validDate')) {
        switch (license.get('reference_date_mode')) {
          case 'issue_date':
            this.set('dateError', 'Issue date cannot be in the future.');
            break;
          case 'expiration_date':
            this.set('dateError', 'Expiration date must be in the future.');
            break;
          default:
            this.set('dateError', 'Please choose a valid date.');
            break;
        }
        return false;
      }
      this.set('dateError', '');
      return true;
    },
    validateImageUpload() {
      if (!Ember.isPresent(this.get('currentLicense.image'))) {
        this.set('imageUploadError', 'Please upload an image.');
        return false;
      }
      this.set('imageUploadError', '');
      return true;
    },
    validateIssuingBody() {
      const isVisible = this.get('issuingBodyVisibility').show;
      const label = this.get('issuingBodyVisibility').label.toLowerCase();
      if (isVisible && !this.get('issuingBody').trim()) {
        const errorMessage = `Please enter the issuing body (${label}).`;
        this.set('issuingBodyError', errorMessage);
        return false;
      }
      this.set('issuingBodyError', '');
      return true;
    },
    formIsValid() {
      const isCertificateTypeValid = this.validateCertificateType();
      if (!isCertificateTypeValid) return false;
      const isLicenseNumberValid = this.validateLicenseNumber();
      const isDateValid = this.validateDate();
      const isImageUploadValid = this.validateImageUpload();
      const isIssuingBodyValid = this.validateIssuingBody();
      const hasErrors = !(isLicenseNumberValid && isDateValid && isImageUploadValid && isIssuingBodyValid);
      return !hasErrors;
    },
    actions: {
      addNewLicense() {
        if (this.formIsValid()) {
          this.store.findAll('license').then(licenses => {
            let license = licenses.findBy('id', this.get('certificateType'));
            if (!license) {
              console.error('No license found with id:', this.get('certificateType'));
              return;
            }
            let issuingBody = license.get('issuer_type') === 'single_issuing_body' ? license.get('issuing_body') : this.get('issuingBody');
            let newLicense = this.get('store').createRecord('pilot-license', {
              license_number: this.get('certificateNumber'),
              reference_date: this.dateInputVisibility ? this.get('inputDate') : undefined,
              issuing_body: issuingBody,
              license: license,
              pilot: this.get('model.pilot'),
              image: this.get('currentLicense.image')
            });
            this.set('isModalOpen', false);
            newLicense.save().then(() => {
              this.clearValidationErrors();
              this.send('showMainPage');
              this.resetForm();
            }).catch(error => {
              console.error('Error saving new license:', error);
            });
          }).catch(error => {
            console.error('Error fetching licenses:', error);
          });
        }
      },
      openModal() {
        this.resetForm();
        this.set('isModalOpen', true);
        this.clearValidationErrors();
      },
      closeModal() {
        this.resetForm();
        this.clearValidationErrors();
        this.set('isModalOpen', false);
      },
      closeModalOutsideClick(event) {
        let insideModal = this.element.querySelector('.modal-container').contains(event.target);
        if (!insideModal) {
          this.send('closeModal');
        }
      },
      previewFile(event) {
        let input = event.target;
        if (input.files && input.files[0]) {
          const file = input.files[0];
          const errorMessage = this.validateImage(file);
          if (errorMessage) {
            this.set('imageUploadError', errorMessage);
            this.set('certificatePreview', '');
            this.set('currentLicense.tempSrc', '');
            this.set('currentLicense.image', null);
            return;
          } else {
            this.set('imageUploadError', '');
          }
          const blobUrl = URL.createObjectURL(file);
          this.set('certificatePreview', blobUrl);
          this.set('currentLicense.tempSrc', blobUrl);
          this.set('currentLicense.image', file);
          const uploadContainer = this.element.querySelector('#uploadContainer');
          if (uploadContainer) {
            uploadContainer.classList.add('has-image');
          }
        } else {
          this.set('certificatePreview', "");
          this.set('currentLicense.tempSrc', "");
          this.set('currentLicense.image', null);
        }
      },
      receiveFile(file) {
        this.send('previewFile', {
          target: {
            files: [file]
          }
        });
      },
      removeFile() {
        if (this.get('currentLicense.tempSrc')) {
          URL.revokeObjectURL(this.get('currentLicense.tempSrc')); // Cleanup the blob URL
        }
        this.set('certificatePreview', null);
        this.set('currentLicense.image', null);
        const uploadContainer = this.element.querySelector('#uploadContainer');
        if (uploadContainer) {
          uploadContainer.classList.remove('has-image');
        }
        this.element.querySelector('#uploadCertificate').value = null;
      },
      dragOverHandler(event) {
        event.preventDefault();
      },
      dragEnterHandler() {
        this.element.querySelector('.upload-container').classList.add('dragging');
      },
      dragLeaveHandler() {
        this.element.querySelector('.upload-container').classList.remove('dragging');
      },
      dropHandler(event) {
        event.preventDefault();
        const item = event.dataTransfer.items ? event.dataTransfer.items[0] : null;
        if (item && item.kind === "file") {
          const file = item.getAsFile();
          this.send('receiveFile', file);
        } else if (event.dataTransfer.files.length > 0) {
          const file = event.dataTransfer.files[0];
          this.send('receiveFile', file);
        }
        this.element.querySelector('.upload-container').classList.remove('dragging');
      },
      handleFileInputChange(event) {
        const file = event.target.files[0];
        if (file) {
          this.send('previewFile', event);
        }
      },
      triggerFileBrowse() {
        const fileInput = this.element.querySelector('#uploadCertificate');
        if (fileInput) {
          fileInput.click();
        }
      },
      showMainPage() {
        this.set('currentPage', 'MAIN');
      }
    }
  });
  _exports.default = _default;
});