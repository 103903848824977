define("pilots/components/pilot-profile-license", ["exports", "pilots/config/environment", "pilots/validations/pilot-license", "ember-changeset-validations", "ember-changeset"], function (_exports, _environment, _pilotLicense, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileLicenseComponent;
  PilotProfileLicenseComponent = Ember.Component.extend({
    pilotLicenseNumber: Ember.computed('index', {
      get: function () {
        if (this.get('index') >= 0) {
          return this.get('index') + 1;
        }
      },
      set: function (key, value) {
        return this._pilotLicenseNumber = value;
      }
    }),
    dateLabel: Ember.computed('pilotLicense.license.reference_date_mode', function () {
      var mode;
      mode = this.get('pilotLicense.license.reference_date_mode');
      switch (mode) {
        case 'issue_date':
          return 'Issue Date';
        case 'expiration_date':
          return 'Expiration Date';
        default:
          return null;
      }
    }),
    actions: {
      destroy: function (license) {
        this.set('pilotLicenseNumber', this.get('pilotLicenseNumber') - 1);
        return this.destroyLicense(license);
      },
      downloadPdf: function (pilotLicense) {
        if (pilotLicense.image && pilotLicense.image.includes('.pdf')) {
          return window.open(pilotLicense.image, '_blank');
        }
      },
      openFile: function (fileUrl) {
        return window.open(fileUrl, '_blank');
      }
    }
  });
  var _default = PilotProfileLicenseComponent;
  _exports.default = _default;
});