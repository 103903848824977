define("pilots/components/time-window-weather", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  var TimeWindowWeatherComponent;
  TimeWindowWeatherComponent = Ember.Component.extend({
    classNames: ['weather-forecast'],
    sessionAccount: Ember.inject.service(),
    numMissions: Ember.computed('timeslots', 'timeslots.current_mission_timeslots', 'timeslots.current_mission_timeslots.length', function () {
      return this.get('timeslots.current_mission_timeslots.length');
    }),
    init: function () {
      this._super();
      if (!this.get('model.duration')) {
        this.set('model.duration', this.get('model.mission.duration'));
      }
      if (this.get('admin_scheduled') && this.get('model.isScheduled')) {
        return this.fetchForecast();
      }
    },
    fetchForecast: function () {
      var _this;
      _this = this;
      return _jquery.default.ajax({
        url: _environment.default.api.host + "/v1/pilots/missions/" + this.get('model.id') + "/weather",
        type: 'GET',
        dataType: 'json',
        headers: _this.get('sessionAccount.headers')
      }).then(function (response) {
        _this.set('loading', false);
        if (response.daily) {
          _this.set('forecast', response.daily);
          _this.set('daily', true);
        } else if (response.hourly) {
          _this.set('forecast', response.hourly);
        }
        return _this.checkForecast();
      }, function (response) {
        return _this.get('rollbar').error('failed to fetch forecast', response);
      });
    },
    actions: {
      selectTimeSlot: function (timeslot) {
        return this.set('selectedTimeSlot', timeslot);
      },
      hideWeather: function () {
        return this.sendAction('hideWeather');
      }
    }
  });
  var _default = TimeWindowWeatherComponent;
  _exports.default = _default;
});