define("pilots/services/google-init", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-classic-classes
  var _default = Ember.Service.extend({
    loading: false,
    loaded: false,
    loadScript: function () {
      if (!this.loaded && !this.loading) {
        this.set('loading', true);
        return Ember.run.scheduleOnce('afterRender', this, this.getScript);
      }
    },
    getScript: function () {
      window.initMap = () => {
        console.log('initMap');
        this.set('loaded', true);
        this.set('loading', false);
      };
      _jquery.default.getScript(`https://maps.googleapis.com/maps/api/js?v=3&key=${_environment.default.googleApiKey}&loading=async&callback=initMap&signed_in=false&libraries=places,drawing,geometry`);
    }
  });
  _exports.default = _default;
});