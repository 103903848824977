define("pilots/models/airspace-briefing", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    external_id: _emberData.default.attr('string'),
    auth_option: _emberData.default.attr('string'),
    requires_auth: _emberData.default.attr('boolean'),
    notices: _emberData.default.attr('array')
  });
  _exports.default = _default;
});