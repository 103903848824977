define("pilots/components/signup-select-certification", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MIN_LICENSE_NUM_LENGTH = 6;
  const MAX_LICENSE_NUM_LENGTH = 32;
  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: 'device-list',
    pages: ['MAIN', 'LICENSES', 'INFORMATION'],
    currentPage: 'MAIN',
    certificateNumber: '',
    inputDate: '',
    issuingBody: '',
    certificatePreview: null,
    pilot: null,
    certificateError: '',
    dateError: '',
    licenseNumberError: '',
    showLicenseNumberError: false,
    imageUploadError: '',
    userHasInteracted: false,
    currentLicense: Ember.computed(function () {
      return {
        tempSrc: null,
        image: null
      };
    }),
    availableLicenses: Ember.computed('model.licenses', function () {
      let licenses = this.get('model.licenses');
      if (!licenses) {
        return [];
      }
      let available = licenses.filter(license => license.issuer_type); // Hacky guarantee that all doc options are in fact docs

      return available.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }),
    init() {
      this._super(...arguments);
      this.set('currentSelectedLicenses', this.get('model.pilotLicenses').toArray());
      this.set('removedLicenses', []);
      this.initializeDateOptions();
    },
    initializeDateOptions() {
      const months = _moment.default.monthsShort().map(month => ({
        name: month.toUpperCase(),
        value: (0, _moment.default)().month(month).format('MM')
      }));
      this.set('months', months);
      const years = Array.from({
        length: 4
      }, (_, i) => (0, _moment.default)().subtract(i, 'years').format('YYYY'));
      this.set('years', years);
      const days = Array.from({
        length: 31
      }, (_, i) => (i + 1).toString().padStart(2, '0'));
      this.set('days', days);
    },
    resetForm() {
      this.setProperties({
        certificateNumber: '',
        inputDate: '',
        issuingBody: '',
        certificatePreview: '',
        certificateError: '',
        dateError: '',
        licenseNumberError: '',
        imageUploadError: ''
      });
      if (this.get('currentLicense')) {
        this.set('currentLicense.tempSrc', '');
        this.set('currentLicense.image', null);
      } else {
        console.warn('currentLicense is undefined during resetForm');
      }
    },
    clearValidationErrors() {
      this.set('dateError', '');
      this.set('certificateError', '');
      this.set('imageUploadError', '');
      this.set('licenseNumberError', '');
      this.set('issuingBodyError', '');
    },
    licenseNumberObserver: Ember.observer('certificateNumber', 'currentLicense.license.id', function () {
      const currentLicense = this.get('currentLicense');
      if (!currentLicense || !currentLicense.get('license')) {
        return;
      }
      const certificateType = currentLicense.get('license.id');
      let license = this.store.peekRecord('license', certificateType);
      const certificateNumber = this.get('certificateNumber').trim();
      const alphaNumerics = /^[0-9a-zA-Z\-#]+$/;
      this.set('licenseNumberError', '');
      this.set('showLicenseNumberError', false);
      if (certificateNumber.length > 0 && license && license.get('is_certificate_number_required')) {
        if (certificateNumber.length < MIN_LICENSE_NUM_LENGTH || certificateNumber.length > MAX_LICENSE_NUM_LENGTH) {
          this.set('licenseNumberError', `License number must contain ${MIN_LICENSE_NUM_LENGTH} to ${MAX_LICENSE_NUM_LENGTH} alphanumeric characters, "#" and "-".`);
          this.set('showLicenseNumberError', true);
        } else if (!alphaNumerics.test(certificateNumber)) {
          this.set('licenseNumberError', 'License number can only contain alphanumeric characters, "#" and "-".');
          this.set('showLicenseNumberError', true);
        }
      }
    }),
    inputDateObserver: Ember.observer('inputDate', 'currentLicense', function () {
      const currentLicense = this.get('currentLicense');
      const license = currentLicense && currentLicense.get('license');
      if (!license) {
        this.set('dateError', '');
        return;
      }
      const inputDate = this.get('inputDate');
      if (!inputDate) {
        this.set('dateError', '');
        return;
      }
      const validDate = this.get('validDate');
      if (!validDate) {
        const mode = license.get('reference_date_mode');
        if (mode === 'expiration_date') {
          this.set('dateError', 'Invalid expiration date.');
        } else if (mode === 'issue_date') {
          this.set('dateError', 'Invalid issue date.');
        } else {
          this.set('dateError', 'Please choose a valid date.');
        }
      } else {
        this.set('dateError', '');
      }
    }),
    issuingBodyObserver: Ember.observer('issuingBody', 'currentLicense', function () {
      const isVisible = this.get('issuingBodyVisibility').show;
      const issuingBody = this.get('issuingBody').trim();
      this.set('issuingBodyError', '');
      if (isVisible && issuingBody.length > 0) {
        if (!issuingBody) {
          this.set('issuingBodyError', `Please enter the issuing body (${this.get('issuingBodyVisibility').label.toLowerCase()}).`);
        }
      }
    }),
    dateErrorClass: Ember.computed('dateError', function () {
      return this.get('dateError') ? 'form-group-calendar-error' : 'form-group-calendar';
    }),
    licenseNumberErrorClass: Ember.computed('licenseNumberError', function () {
      return this.get('licenseNumberError') ? 'form-group-error' : 'form-group';
    }),
    imageUploadErrorClass: Ember.computed('imageUploadError', function () {
      return this.get('imageUploadError') ? 'form-group-error' : 'form-group';
    }),
    selectStyle: Ember.computed('currentLicense.license', function () {
      return this.get('currentLicense.license') ? 'color: rgb(24, 26, 28);' : 'color: rgb(106, 115, 128);';
    }),
    dateInputStyle: Ember.computed('inputDate', function () {
      return this.get('inputDate') ? 'color: rgb(24, 26, 28);' : 'color: rgb(106, 115, 128);';
    }),
    showSection: Ember.computed.or('dateInputVisibility', 'issuingBodyVisibility.show'),
    dateInputLabel: Ember.computed('currentLicense.license', function () {
      const license = this.get('currentLicense.license');
      if (!license) {
        return null;
      }
      const mode = license.get('reference_date_mode');
      switch (mode) {
        case 'issue_date':
          return 'Issue Date';
        case 'expiration_date':
          return 'Expiration Date';
        default:
          return null;
      }
    }),
    dateInputVisibility: Ember.computed('dateInputLabel', function () {
      return Boolean(this.get('dateInputLabel'));
    }),
    issuingBodyVisibility: Ember.computed('currentLicense.license', function () {
      const license = this.get('currentLicense.license');
      if (license && license.get('issuer_type') === 'many_issuing_body') {
        return {
          show: true,
          label: license.get('issuing_body')
        };
      }
      return {
        show: false,
        label: ''
      };
    }),
    validLicenseNumber: Ember.computed('certificateNumber', 'currentLicense.license', function () {
      const certificateNumber = this.get('certificateNumber').trim();
      let license = this.get('currentLicense.license');
      if (!license || !license.get('is_certificate_number_required')) {
        return true;
      }
      if (certificateNumber === '') {
        return false;
      }
      const MIN_LICENSE_NUM_LENGTH = 6;
      const MAX_LICENSE_NUM_LENGTH = 32;
      const alphaNumerics = /^[0-9a-zA-Z\-#]+$/;
      const isLengthValid = certificateNumber.length >= MIN_LICENSE_NUM_LENGTH && certificateNumber.length <= MAX_LICENSE_NUM_LENGTH;
      const isCharValid = alphaNumerics.test(certificateNumber);
      return isLengthValid && isCharValid;
    }),
    validDate: Ember.computed('inputDate', 'currentLicense.license', function () {
      const inputDate = this.get('inputDate');
      const license = this.get('currentLicense.license');
      if (!inputDate || !license) {
        return false;
      }
      const date = (0, _moment.default)(inputDate, 'YYYY-MM-DD');
      if (!date.isValid()) {
        return false;
      }
      const mode = license.get('reference_date_mode');
      const today = (0, _moment.default)();
      switch (mode) {
        case 'issue_date':
          return date.isSameOrBefore(today);
        case 'expiration_date':
          return date.isSameOrAfter(today);
        default:
          return true;
      }
    }),
    validIssuingBody: Ember.computed('issuingBody', 'currentLicense.license', function () {
      const issuingBody = this.get('issuingBody').trim();
      let license = this.get('currentLicense.license');
      if (!license || license.get('issuer_type') !== 'many_issuing_body') {
        return true;
      }
      return issuingBody.length > 0;
    }),
    validateImage(file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'image/heic', 'image/heif', 'image/tiff', 'image/bmp', 'image/webp'];
      const maxSize = 20 * 1024 * 1024; // 20 MB

      let errors = [];
      if (!allowedTypes.includes(file.type)) {
        errors.push('Invalid file type. Only JPEG, PDF, and PNG files are allowed.');
      }
      if (file.size > maxSize) {
        errors.push('File size exceeds the maximum limit of 20 MB.');
      }
      return errors.join(' ');
    },
    validateImageUpload() {
      if (!Ember.isPresent(this.get('currentLicense.image'))) {
        this.set('imageUploadError', 'Please upload an image.');
        return false;
      }
      this.set('imageUploadError', '');
      return true;
    },
    pilotLicenseComplete: Ember.computed('validLicenseNumber', 'validIssuingBody', 'validDate', 'currentLicense.{image,license}', 'imageUploadError', function () {
      const license = this.get('currentLicense.license');
      const isDateRequired = license && license.get('reference_date_mode') !== 'not_applicable';
      const isLicenseNumberValid = this.get('validLicenseNumber');
      const isIssuingBodyValid = this.get('validIssuingBody');
      const isDateValid = !isDateRequired || this.get('validDate');
      const isImageUploadValid = this.get('imageUploadError') === '' && Ember.isPresent(this.get('currentLicense.image'));
      return isLicenseNumberValid && isIssuingBodyValid && isDateValid && isImageUploadValid;
    }),
    pilotLicenseNumber: Ember.computed('index', function () {
      if (typeof this.get('index') === 'number' && this.get('index') >= 0) {
        return this.get('index') + 1;
      }
      return null;
    }),
    setPage(page) {
      const pages = this.get('pages');
      if (pages && pages.includes(page)) {
        this.set('currentPage', page);
        this.setCurrentPage(this.get('currentPage'));
      } else {
        console.error('Invalid page:', page);
      }
    },
    actions: {
      toggleShowSkipModal() {
        this.toggleShowSkipModal();
      },
      showMainPage() {
        this.setPage(this.get('pages')[0]);
        this.send('clearLicenseInfo');
      },
      showLicensesPage() {
        this.setPage(this.get('pages')[1]);
      },
      showCertInfoPage() {
        this.setPage(this.get('pages')[2]);
      },
      selectLicense(license) {
        const pilotLicense = this.get('store').createRecord('pilot-license', {
          license: license,
          pilot: this.get('model.pilot')
        });
        this.set('currentLicense', pilotLicense);
        this.send('showCertInfoPage');
        this.set('userHasInteracted', false);
        this.clearValidationErrors();
      },
      clearLicenseInfo() {
        this.set('currentLicense', null);
        this.resetForm();
        this.set('userHasInteracted', false);
      },
      skipAddLicense() {
        this.send('clearLicenseInfo');
        this.send('showMainPage');
      },
      backSelectLicense() {
        this.send('clearLicenseInfo');
        this.send('showLicensesPage');
      },
      editLicense(pilotLicense) {
        this.set('currentLicense', pilotLicense);
        this.send('showCertInfoPage');
      },
      next() {
        this.get('currentSelectedLicenses').forEach(license => {
          const image = license.get('image');
          license.set('image', undefined);
          license.save().then(function () {
            license.set('image', image);
            license.save();
          });
        });
        this.get('removedLicenses').forEach(license => {
          if (this.get('model.pilotLicenses').toArray().includes(license)) {
            license.destroyRecord();
          }
        });
        this.get('router').transitionTo('signup-flow-preferences');
      },
      removePilotLicense(pilotLicense) {
        this.get('currentSelectedLicenses').removeObject(pilotLicense);
        this.get('removedLicenses').push(pilotLicense);
      },
      addLicense() {
        let currentLicense = this.get('currentLicense');
        currentLicense.setProperties({
          license_number: this.get('certificateNumber'),
          reference_date: this.get('inputDate'),
          issuing_body: this.get('issuingBody'),
          image: this.get('currentLicense.image'),
          pilot: this.get('model.pilot')
        });
        if (!this.get('currentSelectedLicenses').includes(currentLicense)) {
          console.log('Adding license to selected licenses:', currentLicense);
          this.get('currentSelectedLicenses').pushObject(currentLicense);
        } else {
          console.warn('License already exists in selected licenses:', currentLicense);
        }
        this.set('userHasInteracted', false);
        this.send('showMainPage');
      },
      selectMonth(month) {
        this.set('issueMonth', month);
      },
      selectDay(day) {
        this.set('issueDay', day);
      },
      selectYear(year) {
        this.set('issueYear', year);
      },
      previewFile(event) {
        let input = event.target;
        if (input.files && input.files[0]) {
          const file = input.files[0];
          const errorMessage = this.validateImage(file);
          if (errorMessage) {
            this.set('imageUploadError', errorMessage);
            this.set('certificatePreview', '');
            this.set('currentLicense.tempSrc', '');
            this.set('currentLicense.image', null);
            return;
          } else {
            this.set('imageUploadError', '');
          }
          const blobUrl = URL.createObjectURL(file);
          this.set('certificatePreview', blobUrl);
          this.set('currentLicense.tempSrc', blobUrl);
          this.set('currentLicense.image', file);
          const uploadContainer = this.element.querySelector('#uploadContainer');
          if (uploadContainer) {
            uploadContainer.classList.add('has-image');
          }
        } else {
          this.set('certificatePreview', "");
          this.set('currentLicense.tempSrc', "");
          this.set('currentLicense.image', null);
        }
      },
      receiveFile(file) {
        this.send('previewFile', {
          target: {
            files: [file]
          }
        });
      },
      removeFile() {
        if (this.get('currentLicense.tempSrc')) {
          URL.revokeObjectURL(this.get('currentLicense.tempSrc'));
        }
        this.set('certificatePreview', null);
        this.set('currentLicense.image', null);
        const uploadContainer = this.element.querySelector('#uploadContainer');
        if (uploadContainer) {
          uploadContainer.classList.remove('has-image');
        }
        this.element.querySelector('#uploadCertificate').value = null;
      },
      dragOverHandler(event) {
        event.preventDefault();
      },
      dragEnterHandler() {
        this.element.querySelector('.upload-container').classList.add('dragging');
      },
      dragLeaveHandler() {
        this.element.querySelector('.upload-container').classList.remove('dragging');
      },
      dropHandler(event) {
        event.preventDefault();
        const item = event.dataTransfer.items ? event.dataTransfer.items[0] : null;
        if (item && item.kind === "file") {
          const file = item.getAsFile();
          this.send('receiveFile', file);
        } else if (event.dataTransfer.files.length > 0) {
          const file = event.dataTransfer.files[0];
          this.send('receiveFile', file);
        }
        this.element.querySelector('.upload-container').classList.remove('dragging');
      },
      handleFileInputChange(event) {
        const file = event.target.files[0];
        if (file) {
          this.send('previewFile', event);
        }
      },
      triggerFileBrowse() {
        const fileInput = this.element.querySelector('#uploadCertificate');
        if (fileInput) {
          fileInput.click();
        }
      },
      licenseNumberFocusOut() {
        this.set('userHasInteracted', true);
        this.notifyPropertyChange('certificateNumber');
      }
    }
  });
  _exports.default = _default;
});