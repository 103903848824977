define("pilots/helpers/linkify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.linkify = linkify;
  function linkify(_ref) {
    let [text] = _ref;
    if (!text) {
      return '';
    }
    const urlRegex = /(https?:\/\/[^\s)\]]+)/g;
    const linkedText = text.replace(urlRegex, function (url) {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
    return Ember.String.htmlSafe(linkedText);
  }
  var _default = Ember.Helper.helper(linkify);
  _exports.default = _default;
});